import { SliceZone } from "@prismicio/react"

import { createClient } from "../prismic-configuration"
import { components } from "../slices"
import { SliceZoneWrapper } from "../styles"

const Page = (props) => {
  return (
    <SliceZoneWrapper>
      <SliceZone {...props} components={components} />
    </SliceZoneWrapper>
  )
}

export async function getStaticProps({ previewData }) {
  const client = createClient({ previewData })
  const doc = await client.getSingle("homepage")
  return {
    props: {
      ...doc,
      slices: doc.data.slices,
    },
  }
}

export default Page
